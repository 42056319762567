@import "./assets/style/reset.css";

html,
body,
#root {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* react-color */
.material-picker {
  font-family: inherit !important;
}

.material-picker input {
  font-size: 100% !important;
}

.react-daterange-picker__wrapper {
  min-height: 2.125rem;
  padding: 0.25rem;
  transition: border-color 0.3s ease;
  border: 1px solid #eef3f5 !important;
  border-radius: 2px;
}

.ReactVirtualized__Table__headerRow {
  display: flex;
  align-items: center;
  padding-right: 0px !important;
}

.ReactVirtualized__Table__row {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgb(238, 243, 245);
}

.ReactVirtualized__Table__rowColumn {
  flex: 1;
}
